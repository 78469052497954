import React from 'react';
import { graphql } from 'gatsby';

import { Hero } from '../../components/Hero/';
import PageLayout from '../../components/PageLayout/';
import SEO from '../../components/seo';
import FAQAccordion from '../../components/FAQAccordion/';
import { Badges } from '../../components/Badges/';
import { CTASubTitle, CTATitle, CTAWrapper, Wrapper } from '../../styles/faq-styles';

const OrdererFAQ = ({ data: { Postbote } }) => (
  <PageLayout className="bg-white">
    <SEO title="Häufig gestellte Fragen - Besteller" />
    <Hero image={Postbote} title="Häufig gestellte Fragen" subTitle="zur Buchung von DropPoints">
      <div className="font-display font-xl tracking-wide">
        Jetzt die DropFriends-App downloaden.
      </div>

      <Badges />
    </Hero>
    <Wrapper>
      <FAQAccordion question="Wozu brauche ich DropFriends?">
        <p className="mb-4">
          Dank des Internets können wir Bestellungen rund um die Uhr aufgeben. Doch die Zustellung
          der Sendungen ist an Zeiträume gebunden, die den heutigen Ansprüchen mit Berufsalltag und
          Terminen nicht mehr entsprechen. DropFriends verhindert diese umständlichen
          Zustellungsräume von Lieferdiensten in Deinem Alltag. Egal ob Du an Deiner Arbeitsstelle
          oder bei einem Freund zu Besuch bist: Deine Sendungen sind durch DropPoints stets
          zustellbar – lieferdienstunabhängig.
        </p>

        <p>
          Auch der Umwelt wird dabei geholfen. Wusstest Du, dass das Durchschnittspaket 1 kg CO2
          ausstößt? Mit DropFriends werden mehrere Zustellungsversuche durch Lieferdienste und deren
          Benachrichtigungskarten reduziert. Die Wege zu Paketshops und -stationen werden kürzer.
          Auto und Bahn werden zur Abholung somit hinfällig und der CO2-Ausstoß pro Paket wird auf
          bis zu 277 g reduziert.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wie buche ich eine Annahmestelle (DropPoint)?">
        <p className="mb-4">
          Eine Annahmestelle lässt sich ganz einfach über die DropFriends-App, die im App-Store und
          Google-Play-Store erhältlich ist, buchen.
        </p>

        <p>
          Du bist Teil der Community, sobald Du Dich registriert hast und Deine E-Mail-Adresse
          bestätigst. Danach kannst Du unter dem Menüpunkt „Finden“ alle DropPoints in Deiner Nähe
          sehen und Dir einen dieser Punkte per Fingertipp aussuchen. Gleiche anschließend Deine
          Bestellung mit den Annahmebedingungen des DropPoints ab, zum Beispiel im Bereich Größe und
          finde die perfekte Annahmestelle für Dich.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wo ist DropFriends überall verfügbar?">
        <p className="mb-4">Unsere DropPoints sind bundesweit verfügbar.</p>

        <p className="mb-4">
          Die DropFriends-App und all ihre Funktionen sind zudem mit jedem Online-Shop weltweit
          kompatibel. Gib nach der Buchung eines DropPoints die Adresse sowie den
          DropFriends-Buchungscode bei Deiner Bestellung als Lieferadresse an. Daraufhin wird Deine
          Sendung an die entsprechende Adresse übergeben.
        </p>

        <p>Im Übrigen: Der komplette Prozess bei DropFriends läuft lieferdienstunabhängig ab.</p>
      </FAQAccordion>
      <FAQAccordion question="Wann kann ich meine Sendung abholen?">
        <p className="mb-4">
          Jeder DropPoint hat verschiedene Abholzeiten, zu denen Du Deine Pakete mitnehmen kannst.
          Diese Abholzeiten werden Dir bereits bei der Buchung einer Annahmestelle angezeigt. Schaue
          also stets nach, welcher DropPoint den für Dich und Deinen Alltag optimalen Zeitraum
          bietet, um Deine Sendungen abzuholen.
        </p>

        <p className="mb-4">
          Bei der Annahme einer Lieferung wird vom DropPoint eine Paketzustandsdokumentation
          durchgeführt. Das heißt, dass Bilder sowie ein optionaler Text von der Annahmestelle zu
          Deiner Bestellung erstellt werden. Diese Bilder und Texte werden Dir dann durch die
          DropFriends-App in Echtzeit zur Verfügung gestellt. So ist es fast, als hättest Du Deine
          Sendung selbst entgegengenommen.
        </p>

        <p>
          In dem Moment, in dem Dir das Bild Deines Paketes zugeschickt wurde, ist es auch an der
          entsprechenden Adresse zu den angegebenen Zeiten abholbereit.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Welche Größe dürfen meine Pakete haben?">
        <p>
          Die Größe, die Deine Sendung haben darf, hängt generell von der Annahmestelle und dessen
          Akzeptanzkriterien ab. Hierbei wird unterschieden zwischen der ungefähren Größe eines
          Aktenordners, eines Reisekoffers oder eines Getränkekastens. Die vom DropPoint akzeptierte
          Größe wird Dir während der Buchung angezeigt. Gleiche also den angegebenen Wert mit Deiner
          Bestellung ab und suche demnach den passenden Zustellungsort für Dich.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Ist DropFriends auch wirklich sicher?">
        <p className="mb-4">
          Bei DropFriends steht die Sicherheit aller Nutzer und deren Eigentum an erster Stelle. Ob
          nun bei der Paketzustellung oder beim Datenschutz, die Sicherheit der
          DropFriends-Community hat für uns immer höchste Priorität.
        </p>
        <p className="mb-4">
          Bei der Paketzustellung stellen wir mit unterschiedlichen Methoden sicher, dass jede
          Lieferung auch an den rechtmäßigen Eigentümer übergeben wird.
        </p>
        <ol className="list-decimal mb-2 ml-4">
          <li>
            <p className="font-bold">Identifizierung per Buchungscode</p>
            <p>
              Der bei der Buchung von der App erstellte Code wird von der Annahmestelle abgefragt.
            </p>
            <p>
              Der Code besteht aus einer 6-stelligen Buchstaben-/Zahlenkombination und wird von dem
              Zusatz "FUER-NACHNAME-" begleitet. Beispiel: <strong>"FUER-MUSTERMANN-1C23BA"</strong>
              . Dieser Code ist für Dich in der DropFriends-App ersichtlich. Auch in der
              Buchungsbestätigung, die Dir per E-Mail zugeschickt wird, kannst Du den Code einsehen.
              Sobald Du den korrekten Buchungscode nennst, kann Dich der DropPoint auf seiner
              DropFriends-App heraussuchen und ein Fotoabgleich vornehmen.
            </p>
          </li>
          <li>
            <p className="font-bold">Fotoabgleich</p>
            <p>
              Mit Deinem Foto, welches Du in der DropFriends-App hinterlegt hast, nimmt der
              DropPoint bei der Paketübergabe ein Fotoabgleich vor. So kann von der Annahmestelle
              sichergestellt werden, dass die Sendung an ihren Eigentümer übergeben wird.
            </p>
          </li>
          <li>
            <p className="font-bold">Kontrolle eines Lichtbildausweises</p>
            <p>
              Bei der Paketübergabe kann die Annahmestelle optional auch nach einem Lichtbildausweis
              verlangen, bevor er die Lieferung herausgibt. Durch die Hilfe der DropFriends-App kann
              der DropPoint den Lichtbildausweis prüfen.
            </p>
            <p>
              Im Zuge dessen wird die Sicherheit der Paketzustellung auf beiden Seiten garantiert.
            </p>
          </li>
        </ol>
      </FAQAccordion>
      <FAQAccordion question="Woher weiß meine Annahmestelle, dass mir das Paket gehört?">
        <p className="mb-4">
          Jede DropFriends-Sendung hat ihren eigenen individuellen Buchungscode. Entsprechend steht
          dieser auch stets auf dem Versandetikett der jeweiligen Pakete.
        </p>
        <p className="mb-4">
          Der Code besteht aus einer 6-stelligen Buchstaben-/Zahlenkombination und wird von dem
          Zusatz "FUER-NACHNAME-" begleitet. Beispiel: "FUER-MUSTERMANN-1C23BA".
        </p>
        <p>
          So ist gut für den DropPoint sichtbar gekennzeichnet, dass es sich bei der Lieferung um
          Deine DropFriends-Buchung handelt. So kommt es nicht zu versehentlichen Verwechslungen mit
          eigenen Sendungen des DropPoints.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Was ist der DropFriends-Buchungscode?">
        <p className="mb-4">
          Jede Deiner Buchungen hat ihren eigenen individuellen Buchungscode. Dieser Buchungscode
          wird Dir nach jeder Deiner abgeschlossenen Buchungen angezeigt. Zusätzlich kannst Du den
          Code auch in der Buchungsbestätigung, die Dir per E-Mail zugeschickt wird und in der App
          einsehen.
        </p>
        <p className="mb-4">
          Der Code besteht aus einer 6-stelligen Buchstaben-/Zahlenkombination und wird von dem
          Zusatz "FUER-NACHNAME-" begleitet. Beispiel: <strong>"FUER-MUSTERMANN-1C23BA"</strong>.
        </p>
        <p className="mb-4">
          Diesen Code gibst Du dann einfach bei Deiner Bestellung samt der Adresse des DropPoints
          an. So ist gut für den DropPoint sichtbar gekennzeichnet, dass es sich bei dem Paket um
          Deine DropFriends-Buchung handelt. So kommt es nicht zu versehentlichen Verwechslungen mit
          eigenen Sendungen des DropPoints.
        </p>
        <p>
          Zudem dient dieser Buchungscode der Identifizierung von Sendungseigentümern und natürlich
          der korrekten Lieferungen. Bei der Abholung Deines Paketes musst Du der Annahmestelle
          einfach den Code sowie Deinen Namen nennen, damit sichergestellt werden kann, dass die
          Sendung an ihren Eigentümer gelangt. Sobald Du den korrekten Buchungscode nennst, kann
          Dich der DropPoint auf seiner DropFriends-App heraussuchen und ein Fotoabgleich vornehmen.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wo gebe ich den DropFriends-Buchungscode an?">
        <p>
          Bei jeder Buchung eines DropPoints erhältst Du einen Buchungscode, der bei Deiner
          Online-Bestellung mit angegeben werden muss. Diesen Code kannst Du ganz einfach in das
          c/o-Sonderfeld eintragen, welches für mögliche Lieferanweisungen bestimmt ist. Sobald Du
          den Buchungscode samt der Adresse eingetragen hast, kann die Annahmestelle ganz einfach
          erkennen, dass es sich bei dem Paket um eine Sendung eines DropFriends handelt. Zudem wird
          der Code zur Identifikation des Eigentümers genutzt, was für mehr Sicherheit auf beiden
          Seiten sorgt.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Ich kann mein Paket nicht abholen. Was kann ich tun?">
        <p className="mb-4">
          Für den unwahrscheinlichen Fall, dass Du es mal nicht schaffst, Deine Sendung rechtzeitig
          abzuholen, kannst Du Dich an den Service von DropFriends wenden. Dieser wird Dir dann
          helfen, Dein Paket dennoch zu erhalten. Wenn Du bereits im Vorfeld weißt, dass Du Deine
          Lieferung nicht abholen kannst, gib uns sofort Bescheid, damit wir uns so schnell wie
          möglich um Deine Sendung kümmern können.
        </p>
        <p className="mb-4">
          Generell besteht die Möglichkeit, Dein Paket bei dem entsprechenden DropPoint bis zu 120
          Stunden (5 Tage) sicher aufbewahren zu lassen. Wird eine Abholung von Sendungen allerdings
          innerhalb der benannten 120 Stunden versäumt, ist der DropPoint berechtigt, die
          entsprechenden Pakete an DropFriends weiterzuleiten, um die in Anspruch genommenen
          Kapazitäten wieder für weitere Buchungen freizugeben.
        </p>
        <p>
          Du erhältst täglich von der DropFriends-App eine Benachrichtigung, dass Dein Paket beim
          entsprechenden DropPoint bereitliegt, um abgeholt zu werden. Dadurch wird sichergestellt,
          dass Du eine Mitnahme Deiner Sendung auf keinen Fall versäumst.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wie erstelle ich ein DropFriends-Konto?">
        <p className="mb-4">
          Um DropFriends nutzen zu können, ist eine Registrierung über die App DropFriends
          notwendig: erhältlich im Google-Play-Store und App-Store.
        </p>
        <p>
          Nachdem Du Dir die App heruntergeladen hast, kannst Du Dich einfach und schnell bei uns
          registrieren. Gib dazu einfach Deine Daten an und verifiziere Deine E-Mail-Adresse. Nach
          der Bestätigung Deiner E-Mail-Adresse ist Dein DropFriends-Konto nun aktiviert und die
          effiziente Paketzustellung kann beginnen.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wie kann ich meine Buchung stornieren?">
        <p>
          Um eine Buchung eines DropPoints zu stornieren, kannst Du Dich ganz einfach per E-Mail an
          unseren Kundenservice wenden, welcher sich Deinen Sachverhalt in Detail anschauen wird.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wie kann ich meiner Annahmestelle ein Trinkgeld geben?">
        <p className="mb-4">
          Beliebte DropPoints helfen der Community, viel Zeit und Nerven zu sparen. Da ist es nur
          selbstverständlich, dass Sendungseigentümer sich besonders für die Hilfe erkenntlich
          zeigen möchten; besonders an Feiertagen und Ferienzeiten.
        </p>
        <p className="mb-4">
          Deswegen gibt es nach jeder erfolgreichen Sendungsübergabe für Dich die Möglichkeit, der
          Annahmestelle ein Trinkgeld zu geben.
        </p>
        <p className="mb-4">
          Rufe hierzu den Menüpunkt "Meine Pakete" auf, um die entsprechenden Buchungen per
          Fingertipp zu öffnen.
        </p>
        <p>
          Unter der Kategorie "Trinkgeld & Bewertung" kannst Du den gewünschten Betrag auswählen und
          Deinem DropFriend im gleichen Atemzug noch eine positive Bewertung hinterlassen. Positive
          Bewertungen helfen einem DropPoint dabei, einem Vergütungsschwellenaufstieg näherzukommen
          und so in Zukunft pro angenommene Sendung mehr Geld verdienen zu können.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Wie kann ich in der App nach meiner Sendung suchen?">
        <p className="mb-4">
          Jede Sendung hat ihren eigenen individuellen Buchungscode. Dieser Buchungscode wird Dir
          nach jeder Deiner abgeschlossenen Buchungen angezeigt. Zusätzlich kannst Du den Code auch
          in der Buchungsbestätigung, die Dir per E-Mail zugeschickt wird und in der App einsehen.
        </p>
        <p className="mb-4">
          Der Code besteht aus einer 6-stelligen Buchstaben-/Zahlenkombination und wird von dem
          Zusatz "FUER-NACHNAME-" begleitet. Beispiel: <strong>"FUER-MUSTERMANN-1C23BA"</strong>.
        </p>
        <p className="mb-4">
          Unter dem Reiter „Meine Pakete“ ist es Dir möglich, jederzeit nachzuschauen, wohin Du
          welches Paket hast senden lassen. Mit der intuitiven Filterlösung in der DropFriends-App
          kannst Du ganz einfach nachvollziehen, welche Sendung zu welchem DropPoint geschickt
          wurde. Solltest Du Deine Sendungen nicht direkt finden, dann gib den 6-stelligen
          Buchungscode in der Suchmaske „Verschickte Pakete“ ein.
        </p>
        <p>
          Prüfe auch, ob Deine "Filtereinstellungen" neben dem Suchfeld korrekt eingestellt sind.
          Werden z. B. alle bereits abgeholten Pakete über die Filter ausgeblendet, dann werden Dir
          keine Suchergebnisse trotz korrekter Eingabe des 6-stelligen Buchungscodes angezeigt.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Warum ist meine Lieblingsannahmestelle nicht buchbar?">
        <p className="mb-4">
          Jedem DropPoint steht es frei, selbst zu bestimmen, wie viele Sendungen gleichzeitig für
          die Community angenommen werden.
        </p>
        <p className="mb-4">
          Ist die von der Annahmestelle angegebene Kapazität ausgeschöpft, sind keine weiteren
          Buchungen des DropPoints mehr möglich, bis mindestens eine der Sendungen erfolgreich an
          den Eigentümer übergeben worden ist. Daher werden also ausgebuchte Annahmestellen auch
          nicht mehr auf der Karte angezeigt, um mögliche Missverständnisse vorzubeugen.
        </p>
        <p>
          Sobald mindestens eine der Sendungen des DropPoints erfolgreich an den Eigentümer
          übergeben worden ist, wird die Annahmestelle wieder auf der Karte sichtbar.
        </p>
      </FAQAccordion>
    </Wrapper>

    <CTAWrapper>
      <CTATitle>Jetzt die DropFriends-App downloaden.</CTATitle>

      <CTASubTitle>Erhältlich im App Store und im Google Play Store.</CTASubTitle>

      <Badges className="mt-4" />
    </CTAWrapper>
  </PageLayout>
);

export const query = graphql`
  {
    Postbote: file(relativePath: { eq: "besteller.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default OrdererFAQ;
